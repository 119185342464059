// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/sprites.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Image */\n/* Size */\n/* Color */\n/* Input */\n/* Font */\n/* Combo */\n/* Menu */\n/* Image */\n/* Size */\n/* Color */\n/* Input */\n/* Font */\n/* Combo */\n/* Menu */\n.base-input[data-v-0dd7a756] {\n  align-items: center;\n  padding: 0px;\n}\n.base-input input[data-v-0dd7a756] {\n  background: #fff;\n  cursor: text;\n}\n.base-input .ms-number-item[data-v-0dd7a756] {\n  border: none;\n  padding: 9px 12px;\n  text-align: left;\n}\n.base-input .ms-number-item[data-v-0dd7a756]:focus {\n  outline: none;\n}\n.base-input .ms-number-spin[data-v-0dd7a756] {\n  width: 28px;\n  min-width: 28px;\n  height: 26px;\n  border-left: 1px solid #c1c4cc;\n}\n.base-input .spin[data-v-0dd7a756] {\n  position: relative;\n}\n.base-input .spin[data-v-0dd7a756]::before {\n  content: \"\";\n  position: absolute;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat -96px -48px;\n  width: 28px;\n  height: 10px;\n  left: 0px;\n}\n.base-input .spin.up[data-v-0dd7a756]::before {\n  transform: rotate(180deg);\n  top: 4px;\n}\n.base-input .spin.down[data-v-0dd7a756]::before {\n  top: 2px;\n}\n.base-input .spin[data-v-0dd7a756]:hover {\n  cursor: pointer;\n}\ninput.has-border[data-v-0dd7a756] {\n  border: 1px solid #c9c9c9;\n  border-radius: 3px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
