import BaseAPI from '@/apis/base/baseapi.js';
import httpClient from '@/apis/base/httpClient.js';
class DossierAPI extends BaseAPI {
    controllerName = 'Dossiers';

    /**
     * Lấy hồ sơ đã sinh
     * @param {*} dossierId 
     */
    async getTimesheetByDossierID(dossierId) {
        let request = {
            url: [this.getAPIUrl(), 'Timesheet', dossierId].join('/'),
        };

        return httpClient.getAsync(request);
    }

}



export default new DossierAPI();