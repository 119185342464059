<template>
    <div class="loading">
        <div class="loadingio-spinner-cube-mbe1vl0t5jb">
            <div class="ldio-2msfvsc59wo">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
export default {
    components:{
        ContentLoader
    },
    setup(){
       
    }
}
</script>

<style type="text/css">
@keyframes ldio-2msfvsc59wo {
    0% {
        transform: scale(1.1500000000000001)
    }

    100% {
        transform: scale(1)
    }
}

.loading {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 999999;
    background: black;
    opacity: 0.5;
}

.ldio-2msfvsc59wo div {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 13.333333333333336px;
    left: 13.333333333333336px;
    background: #e15b64;
    animation: ldio-2msfvsc59wo 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation-delay: -0.3s;
    opacity: 1 !important;
}

.ldio-2msfvsc59wo div:nth-child(2) {
    top: 13.333333333333336px;
    left: 33.666667px;
    background: #f47e60;
    animation-delay: -0.2s;
}

.ldio-2msfvsc59wo div:nth-child(3) {
    top: 33.666667px;
    left: 13.333333333333336px;
    background: #abbd81;
    animation-delay: 0s;
}

.ldio-2msfvsc59wo div:nth-child(4) {
    top: 33.666667px;
    left: 33.666667px;
    background: #f8b26a;
    animation-delay: -0.1s;
}

.loadingio-spinner-cube-mbe1vl0t5jb {
    width: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    
}

.ldio-2msfvsc59wo {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-2msfvsc59wo div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */
</style>