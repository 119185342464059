export const defaultConfigState = {
  /**
   * Token đăng nhập
   */
  Token: null,
  /**
   * Cờ đang xử lý
   */
  Loading: false,
  Path: "",
  To: {},
  Context: {
  }
}
export default {
  ...defaultConfigState
}