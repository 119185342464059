/**
 * Trạng thái của model
 */
export default {
  None: 0,
  Insert: 1,
  Update: 2,
  Delete: 3,
  Empty: 4,
  Duplicate: 5,
}