import Http from '@/apis/base/httpConfig.js';
import BaseAPI from '@/apis/base/baseapi.js';
class CustomerAPI extends BaseAPI {
    controllerName = 'Customers';


}



export default new CustomerAPI();