// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/icon/loading.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Image */\n/* Size */\n/* Color */\n/* Input */\n/* Font */\n/* Combo */\n/* Menu */\n/*-------loading--------*/\n*, ::after, ::before {\n  box-sizing: border-box;\n}\n\n.loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  background: rgba(221, 221, 221, 0.2);\n  z-index: 99999;\n  top: 0;\n  left: 0;\n}\n.loading .loading-cover {\n  width: 100%;\n  height: 100%;\n  background: #fff;\n  display: block;\n  position: absolute;\n  z-index: 9998;\n  opacity: 0.5;\n}\n.loading .loading-svg {\n  height: 50px;\n  width: 50px;\n  position: relative;\n}\n.loading .loading-svg .effects {\n  transition: all 0.3s ease;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border: none;\n  border-radius: 50%;\n  box-sizing: border-box;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 100%;\n}\n.loading .loading-svg .effect-1 {\n  animation: rotate 1s ease infinite;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border: none;\n  border-radius: 50%;\n  box-sizing: border-box;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 100%;\n}\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
